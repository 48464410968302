<template>
<div class="row" style="padding: 10px;">
  <div class="col-md-12" v-if="booking">
    <div> {{ booking.route ? booking.route.code: '' }}</div>
    <div class=""><span style="font-weight: bold;">{{ booking.booking_date }} | {{ booking.formatted_time }}</span></div>

  </div>

  <div class="col-md-8" v-if="ticket">
    <vessel-seat-plan-form :accommodation="ticket.accommodation" :vessel_detail="ticket.booking.vessel" :ticket="selected" :taken_seats="seats" @takeSeat="handleTakeSeat($event)"></vessel-seat-plan-form>
  </div>
</div>
</template>

<script>
import VesselSeatPlanForm from '../Vessels/VesselSeatPlanForm.vue'
import {
  Booking
} from '@/resources/booking'
import {
  Ticket
} from '@/resources/ticket'

export default {
  props: ['ticket', 'booking'],
  components: {
    VesselSeatPlanForm
  },
  data() {
    return {
      selected: null,
      seats: [],
      term: null,
      tickets: []
    }
  },
  watch: {
    ticket: function () {
      if (this.ticket) {
        this.selected = Object.assign({}, this.ticket)
        this.term = this.ticket.ticket_detail ? this.ticket.ticket_detail.code : this.ticket.code
        this.tickets = [this.ticket]

        this.getTakenSeats()
      }
    }
  },
  created: function () {
    if (this.ticket) {
      this.selected = Object.assign({}, this.ticket)
      this.term = this.ticket.ticket_detail ? this.ticket.ticket_detail.code : this.ticket.code
      this.tickets = [this.ticket]

      this.getTakenSeats()
    }
    
  },
  methods: {
    handleTakeSeat(seatNumber) {
      this.$loading = true
      Ticket.saveSeatNumber(this.selected.id, {
          seat_number: seatNumber
        })
        .then(result => {
           this.$notify({
            type: 'success',
            title: "Success",
            text: 'Seat number has been assigned',
           });
          this.getTakenSeats()
          if (this.selected.print) {
             this.handleDownloadBoardingPass()
          } else {
            this.$emit('save', result.data.ticket_seat)
          }
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadBoardingPass() {
      this.$loading = true
      this.$loadingText = "Preparing to generate boarding pass"

      Ticket.downloadBoardingPass(this.selected.id)
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
          this.$emit('save', true)
        })
        .catch(() => {


        }).finally(() => {
          this.$loading = false
        })
    },
    getTakenSeats() {
      this.$loading = true
      Booking.getTakenSeats(this.selected.booking.id, {
          accommodation_id: this.selected.accommodation.id
        })
        .then(result => {
          this.seats = result.data.seats
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
