<template>
  <div v-if="vessel_detail">
    <h4>{{ vessel_detail.code }} - {{ accommodation.name }}</h4>
    <small class="text-white active-seat padding-5">GRAY - AVAILABLE </small>
    <small class="text-white taken-seat padding-5">YELLOW - TAKEN  </small>
    <small class="text-white padding-5" style="background: #3e3f3f;">BLACK - UNAVAILABLE</small>
    <hr>
    <div class="row" v-if="!ticket && !booking">
      <div class="col-md-2 mb-2">
        <strong>Add Seats</strong>
        <el-input v-model="rows" size="mini">
          <template slot="prepend">Rows</template>
        </el-input>
        <el-input v-model="columns" size="mini">
          <template slot="prepend">Columns</template>
        </el-input>
        <div class="mt-2">
          <el-button size="mini" type="primary" plain @click="handleAddSeats()">Add</el-button>
          <el-button size="mini" type="danger" plain @click="handleRemoveAll()">or Remove all seats</el-button>
        </div>
      </div>
      <div class="col-md-3" v-if="vessel">
        TOTAL ACTIVE SEATS: <br>
        <div style="font-size: 50px;">{{ vessel.total }}</div>
      </div>

    </div>
    <div v-for="row,i in generated_seats" :key="i" style="margin-bottom: 20px;min-width: 1000px;">
      <!-- <el-input v-model="row.col_size" size="mini" style="width: 180px;margin-right: 10px;" class="ml-2">
      <el-button slot="prepend" icon="el-icon-edit" @click="updateColumn(i)">Set Columns</el-button>
    </el-input> -->

      <span class="seat-number-box" v-for="col in row.columns" :key="col"
        :class="{ 'aisle-seat': isAisle( AddLeadingZero(i + 1), col), 'active-seat': isActive(AddLeadingZero(i + 1), col), 'taken-seat': isSeatTaken(AddLeadingZero(i + 1), col) }">
        <div style="padding-left: 5px;">
          <span class="seat-number-text">{{ AddLeadingZero(i + 1) }}{{ col }}</span><br>
          <el-link v-if="!ticket && !booking" style="font-size: 10px;"
            @click="handleAddSpace(AddLeadingZero(i + 1), col)">{{ isAisle(AddLeadingZero(i + 1), col) ? 'RM SPACE':'ADD SPACE'}}</el-link><br>
          <el-link v-if="!ticket && !booking" style="font-size: 10px;"
            @click="handleBlock(AddLeadingZero(i + 1), col)">{{ isActive(AddLeadingZero(i + 1), col) ? 'BLOCK' :
            'UNBLOCK' }}</el-link>
          <el-link v-if="ticket && !isSeatTaken(AddLeadingZero(i + 1), col) && isActive(AddLeadingZero(i + 1), col)"
            style="font-size: 15px;" @click="handleTakeSeat(AddLeadingZero(i + 1), col)">Take</el-link>

        </div>

      </span>

    </div>
  </div>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Vessel
} from '@/resources/vessel'
import {
  Accommodation
} from '@/resources/accommodation'

import {
  Booking
} from '@/resources/booking'

extend("required", required);

export default {
  props: ['vessel_detail', 'accommodation', 'ticket', 'taken_seats', 'booking'],
  data() {
    return {
      form: null,
      accommodations: [],
      seats: [],
      generated_seats: [],
      rows: 0,
      columns: 0,
      vessel: null,
      taken_seats_from_booking: []
    };
  },
  watch: {
    accommodation: function () {
      this.getSeats()
    }
  },
  created: function () {
    this.getSeats()
  },
  methods: {
    handleTakeSeat(row, col) {
      this.$emit('takeSeat', row + col)
    },
    handleAddSpace(row, col) {
      let form = {
        seat_number: row + col,
        is_aisle: !this.isAisle(row, col),
        status: this.isActive(row, col),
        accommodation_id: this.accommodation.id,
        vessel_detail_id: this.vessel_detail.id
      }
      this.updateSeat(form)
    },
    handleBlock(row, col) {
      let form = {
        seat_number: row + col,
        is_aisle: this.isAisle(row, col),
        status: this.isActive(row, col) ? 0 : 1,
        accommodation_id: this.accommodation.id,
        vessel_detail_id: this.vessel_detail.id
      }
      this.updateSeat(form)
    },
    handleRemoveAll() {
      this.$loading = true

      Vessel.removeAllSeats(this.vessel_detail.id, {
        accommodation_id: this.accommodation.id
      })
        .then(result => {
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    updateSeat(form) {
      this.$loading = true

      Vessel.updateSeat(this.vessel_detail.id, form)
        .then(result => {
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    AddLeadingZero(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    isSeatTaken(row, col) {
      let active = false
      let seats = this.taken_seats_from_booking || []
      if (!this.taken_seats_from_booking.length) seats = this.taken_seats || []
      seats.forEach(function (seat) {
        if (seat == row + col) {
          active = true
        }
      })
      return active
    },
    isActive(row, col) {
      let active = false
      let self = this

      this.seats.forEach(function (seat) {
        if (seat.seat_number == row + col) {
          if (seat.status == 1) active = true
        }
      })
      return active
    },
    isAisle(row, col) {
      let aisle = false
      let self = this

      this.seats.forEach(function (seat) {
        if (seat.seat_number == row + col) {
          if (seat.is_aisle) aisle = true
        }
      })
      return aisle
    },
    updateColumn(i) {
      let self = this
      this.generated_seats.forEach(function (seat, index) {
        if (index == i) {
          seat.columns = []
          for (let c = 1; c <= seat.col_size; c++) {
            seat.columns.push(self.numberToAlphabet(c))
          }
        }
      })
    },
    alphabethToNumber(letter) {
      // Convert the letter to uppercase to handle both cases
      let uppercaseLetter = letter.toUpperCase();

      // ASCII value of 'A'
      let asciiValueOfA = 'A'.charCodeAt(0);

      // Get the ASCII value of the input letter
      let asciiValueOfInput = uppercaseLetter.charCodeAt(0);

      // Calculate the position by subtracting the ASCII value of 'A'
      let position = asciiValueOfInput - asciiValueOfA + 1;

      // Return the position
      return position;
    },
    numberToAlphabet(number) {
      if (number < 1 || number > 26) {
        return "Number out of range (1-26)";
      }

      return String.fromCharCode(64 + number); // 64 is the ASCII code for 'A'
    },
    handleAddSeats() {
      this.$loading = true
      Vessel.addSeats(this.vessel_detail.id, {
        accommodation_id: this.accommodation.id,
        rows: this.rows,
        columns: this.columns
      })
        .then(result => {
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
    generateSeats(row = 0, col = 0) {
      this.generated_seats = []
      for (let r = 0; r < parseInt(row); r++) {
        let addtlRow = {
          columns: [],
          col_size: 0
        }
        for (let c = 1; c <= col; c++) {
          addtlRow.columns.push(this.numberToAlphabet(c))
        }

        addtlRow.col_size = addtlRow.columns.length

        this.generated_seats.push(addtlRow)
      }
      this.getTakenSeatsFromBooking()
    },
    getTakenSeatsFromBooking() {
      if (!this.booking) return

      this.$loading = true
      Booking.getTakenSeats(this.booking.id, {
        accommodation_id: this.accommodation.id
      })
        .then(result => {
          this.taken_seats_from_booking = result.data.seats
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getSeats() {
      this.$loading = true
      this.generated_seats = []
      Vessel.getSeats(this.vessel_detail.id, {
        accommodation_id: this.accommodation.id
      })
        .then(result => {
          this.vessel = result.data.vessel
          if (result.data.vessel_seats) this.seats = result.data.vessel_seats
          this.generateSeats(parseInt(result.data.last_row_seat), this.alphabethToNumber(result.data.last_column_seat))
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getAccommodations() {
      this.$loading = true
      Accommodation.get({
        params: {}
      })
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
