<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <port-form @save="getPorts()" v-if="showForm" :port="selected"></port-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Ports</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="ports" style="width: 100%">

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="CODE">
              <template slot-scope="props">
                {{ props.row.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="FCA">
              <template slot-scope="props">
                {{ money(props.row.fca) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="TerFee">
              <template slot-scope="props">
                {{ money(props.row.terminal_fee) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="ADDRESS">
              <template slot-scope="props">
                {{ props.row.full_address }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="CONTACT NO">
              <template slot-scope="props">
                {{ props.row.contact_number }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="TICKET FORMAT">
              <template slot-scope="props">
                {{ props.row.ticket_format_type == 1 ? 'THERMAL':'DEFAULT' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="TICKET EXPIRATION">
              <template slot-scope="props">
                AFTER {{ props.row.ticket_expiration_months }} MONTH(S)
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="REBOOKING FEE">
              <template slot-scope="props">
                <div>{{ money(props.row.rebooking_charge_rate_before_departure) }} <br>BEFORE DEPARTURE</div>
                <div>{{ money(props.row.rebooking_charge_rate_after_departure) }} <br>AFTER DEPARTURE</div>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="REFUND FEE">
              <template slot-scope="props">
                <div>{{ money(props.row.refund_charge_rate_before_departure) }} <br>BEFORE DEPARTURE</div>
                <div>{{ money(props.row.refund_charge_rate_after_departure) }} <br>AFTER DEPARTURE</div>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
                <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Port
} from '@/resources/port'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import PortForm from './PortForm.vue'

export default {
  components: {
    ConfirmDelete,
    PortForm
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      ports: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPorts()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPorts()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPorts()
    }
  },
  created: function () {
    this.getPorts()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      Port.delete({
          id: model.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Port has been removed',
          });
          this.getPorts()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPorts() {
      this.handleClose()
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      Port.get({
          params: params
        })
        .then(result => {
          if (result.data.ports)
            this.ports = result.data.ports
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch ports',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
