<template>
  <transition name="fade">

    <el-card v-if="ticket" style="margin-bottom: 20px;min-height: 320px;">
      <el-drawer title="" :visible.sync="showPdf" direction="btt" :before-close="handleClose" size="90%"
        :append-to-body="true" :destroy-on-close="true">
        <ticket-pdf-viewer :url="url" v-if="url" @print="handleTicketPrinted()"></ticket-pdf-viewer>
      </el-drawer>

      <el-drawer title="" :visible.sync="showReschedulesHistory" direction="btt" :before-close="handleClose" size="80%"
        :append-to-body="true" :destroy-on-close="true">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <reschedules-history :ticket="selected" v-if="selected"></reschedules-history>
          </div>
        </div>
      </el-drawer>

      <el-drawer title="" :visible.sync="showResched" direction="btt" :before-close="handleClose" size="90%"
        :append-to-body="true" :destroy-on-close="true">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <reschedule-ticket-form :ticket="selected" v-if="selected" @save="handleClose"></reschedule-ticket-form>
          </div>
        </div>
      </el-drawer>

      <el-drawer title="" :visible.sync="showUpgrade" direction="btt" :before-close="handleClose" size="90%"
        :append-to-body="true" :destroy-on-close="true">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <upgrade-ticket-form :ticket="selected" v-if="selected" @save="handleClose"></upgrade-ticket-form>
          </div>
        </div>
      </el-drawer>

      <a>
        <h5 class="title">{{ ticket.ticket_detail.passenger.full_name }}</h5>
      </a>
      <ul class="list-unstyled team-members">
        <li>
          <div class="row">
            <div class="col-md-3 col-3 ">
              CODE
            </div>
            <div class="col-md-9 col-9 text-right">
              <small class="text-danger"> {{ ticket.ticket_detail.code }}</small>
            </div>

            <div class="col-md-3 col-3 ">
              SCHEDULE
            </div>
            <div class="col-md-9 col-9 text-right">
              <span class="text-muted">{{ ticket.booking.booking_date }} | {{ ticket.booking.formatted_time
                }}</span>
            </div>
            <div class="col-md-3 col-3 ">

            </div>
            <div class="col-md-9 col-9 text-right">
              <span class="text-muted">{{ ticket.booking.route.code }} | {{ ticket.accommodation.code }} | {{
                $store.state.passenger_types[ticket.passenger_type] }}</span>
            </div>
            <div class="col-md-6 col-6 ">
              TOTAL FARE
            </div>
            <div class="col-md-6 col-6 text-right">
              <span class="text-muted">{{ money(ticket.ticket_detail.total_fare) }}</span>
            </div>
            <div class="col-md-4 col-4 ">
              SEAT NO
            </div>
            <div class="col-md-8 col-8 text-right">
              <span class="text-danger">{{ ticket.ticket_seat ?
                ticket.ticket_seat.seat_number : ''
                }}</span>
            </div>

            <div class="col-md-4 col-4 ">
              STATUS
            </div>
            <div class="col-md-8 col-8 text-right font-12">
              <div v-if="ticket.is_refunded" class="text-danger">REFUNDED</div>
              <div v-else class="text-success">
                {{ $store.state.tickets_status[ticket.status] }}
              </div>
              <div v-if="ticket.has_been_rescheduled" style="font-size: 10px;" class="text-danger">
                (RESCHEDULED) <el-link type="warning" @click="handleShowReschedulesHistory()"
                  style="font-size: 10px;">(SHOW)</el-link>
              </div>

              <div v-if="ticket.reject_status == 1">
                (REJECT REQUESTED)
              </div>
              <div v-if="ticket.reject_status == 2">
                (REJECTED)
              </div>

              <div v-if="ticket.is_expired">EXPIRED</div>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div style="text-align: center;">
        <div v-if="ticket.status == 2">

          <!-- <el-tooltip class="item" effect="dark" content="Reject Ticket" placement="top"
          v-if="ticket.can_reject && user_id == ticket.created_by_id">
          <confirm-button title="You're requesting to reject this ticket. Ready to proceed?" label="" :size="'small'"
            :type="'danger'" :icon="'fa fa-ban'" class="mr-2" @onClick="handleRequestReject(props.row)">
          </confirm-button>

        </el-tooltip> -->


          <el-tooltip class="item" effect="dark" content="Print Ticket" placement="top" v-if="ticket.can_print">
            <el-button type="default" link @click="handleDownloadTicket" size="mini">
              <i class="fa fa-download"></i>
            </el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark"
            :content="'Refund ' + $store.state.refund_types[ticket.refund_type_option] + ' ' + ticket.refund_rate + ' %'"
            placement="top" v-if="ticket.can_refund && isAllowed('tickets', 'update')">
            <el-button type="info" link @click="handleRefund" size="mini" v-if="ticket.refund_type_option != null">
              <i class="fa fa-minus-square"></i>
            </el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark"
            :content="'Rebooking ' + $store.state.resched_types[ticket.resched_type_option] + ' ' + ticket.rebooking_rate + ' %'"
            placement="top" v-if="ticket.can_reschedule && isAllowed('tickets', 'update')">
            <el-button type="primary" link @click="handleResched" size="mini" v-if="ticket.resched_type_option != null">
              <i class="fa fa-mail-forward"></i>
            </el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" :content="'Upgrade'" placement="top"
            v-if="ticket.is_upgradable && isAllowed('tickets', 'update') ">
            <el-button type="warning" link @click="handleUpgrade" size="mini">
              <i class="fa fa-upload"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </el-card>
  </transition>

</template>

<script>
import {
  Ticket
} from '@/resources/ticket'
import ReschedulesHistory from '../Ticketing/ReschedulesHistory.vue'
import TicketPdfViewer from '../Ticketing/TicketPdfViewer.vue'
import Swal from 'sweetalert2'
import RescheduleTicketForm from '../Ticketing/RescheduleTicketForm.vue'
import UpgradeTicketForm from '../Ticketing/UpgradeTicketForm.vue'

export default {
  components: { ReschedulesHistory, TicketPdfViewer, RescheduleTicketForm, UpgradeTicketForm },
  props: ['ticket'],
  data() {
    return {
      showReschedulesHistory: false,
      selected: null,
      url: null,
      showPdf: false,
      showResched: false,
      showUpgrade: false
    }
  },
  methods: {
    handleTicketPrinted() {
      this.showPdf = false
    },
    handleShowReschedulesHistory() {
      this.selected = Object.assign({}, this.ticket)
      this.showReschedulesHistory = true
    },
    handleClose() {
      this.showReschedulesHistory = false
      this.showPdf = false
      this.showResched = false
      this.showUpgrade = false
      this.$emit('close')
    },
    handleDownloadTicket() {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.downloadTicket(this.ticket.id)
        .then(result => {
          this.url = result.data.url
          this.showPdf = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    async handleRefund() {
      let ticket = this.ticket
      let message = ticket.refund_type_option != null ? this.$store.state.refund_types[ticket.refund_type_option] + ' ' + ticket.refund_rate + ' %' : ''
      if (ticket.refund_rate == 0) message = "(Free)"
      const {
        value: res
      } = await Swal.fire({
        title: message + "  Refund ",
        text: "This will charge the ticket " + this.money(ticket.expected_refund_amount),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-info",
        buttonsStyling: false
      })

      if (!res) return

      this.$loading = true
      Ticket.refund(ticket.id)
        .then(result => {
          this.successRefund(ticket)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    successRefund() {
      Swal.fire({
        title: "Success",
        text: "The ticket of passenger " + this.ticket.ticket_detail.passenger.full_name + ' has been refunded',
        icon: "success",
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })
      this.handleClose()
    },
    handleResched() {
      this.selected = Object.assign({}, this.ticket)
      this.showResched = true
    },
    handleUpgrade() {
      this.selected = Object.assign({}, this.ticket)
      this.showUpgrade = true
    },
  }
}
</script>

<style lang="scss"></style>
