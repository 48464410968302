<template>
  <div class="row">
     <div class="col-md-4"></div>
      <div class="col-md-4" style="text-align: center;">
        <div v-if="booking" style="font-weight: bold;font-size: 22px;">
          {{ booking.booking_date }} | {{ booking.formatted_time }} <br>
          {{ booking.route.code }}
        </div>
        <hr>
        <div>CURRENT STATUS:</div>
        <h5> {{ getCurrentStatus() }}</h5>
      </div>
       <div class="col-md-4"></div>
    <div class="col-md-4"></div>
   <div class="col-md-4" style="text-align: center;margin: 10px;">
    <div class="row">
      <div class="col-md-12">
      <el-button type="danger" @click="handleUpdate(0)" class="btn-block mb-2">CANCEL</el-button>
      </div>
      <div class="col-md-12">
            <el-button type="primary" @click="handleUpdate(1)" class="btn-block mb-2">OPEN </el-button>
          </div>
        <div class="col-md-12">
                  <el-button type="info" @click="handleUpdate(3)" class="btn-block mb-2">HOLD ISSUANCE</el-button>
          </div>
        <div class="col-md-12">
          <hr>
          <div class="form-group">
              <label>Departure Time</label>
              <div>Please provide the actual departure time for delayed trips</div>
              <el-time-select
                v-model="departure_time"
                :picker-options="{
                  start: '03:00',
                  step: '00:05',
                  end: '21:00'
                }"
                placeholder="Select time">
              </el-time-select>
            </div>
            <el-button type="success" @click="handleUpdate(2)" class="btn-block mb-2">DEPART</el-button>
          </div>
    </div>

   </div>
  </div>
</template>
<script>

import { Booking } from '@/resources/booking'


export default {
  props: ['booking'],
  data() {
    return {
      departure_time: null
    }
  },
 
  created: function () {
    if (this.booking) this.departure_time = this.booking.time
  },
  methods: {
    getCurrentStatus() {
      if (!this.booking) return "N/A"
      if (this.booking.status == 0) return 'CANCELLED'
      if (this.booking.status == 1) return 'OPEN'
      if (this.booking.status == 2) return 'DEPARTED'
         if (this.booking.status == 3) return 'ON HOLD'
    },
    handleUpdate(status) {
      this.$loading = true
      Booking.updateStatus(this.booking.id, {status: status, departure_time: this.departure_time })
        .then(result => {
          this.$emit('save', true)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
