import api from './api'

const API = '/api/client/analytics'

export const Analytic = {
  topRoutes: function(params) {
    return api.create(API + '/top-routes', params)
  },
  cancelledTripsToday: function(params) {
    return api.create(API + '/cancelled-trips-today', params)
  },
  departedTripsToday:function(params) {
    return api.create(API + '/departed-trips-today', params)
  },
  totalPassengersBookedToday: function(params) {
    return api.create(API + '/passengers-booked-today', params)
  },
  totalOnlinePassengersBookedToday: function(params) {
    return api.create(API + '/online-passengers-booked-today', params)
  },
  topHighestTripSalesToday: function(params) {
    return api.create(API + '/top-highest-trip-sales-today', params)
  },
  totalSalesToday: function(params) {
    return api.create(API + '/total-sales-today', params)
  }
}

