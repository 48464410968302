<template>
<div class="row">
  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true" :append-to-body="true">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <trip-generate-form @save="getTrips()" :trip_schedule="trip_schedule"></trip-generate-form>
      </div>
    </div>

  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-header">
      <div class="category">Manage Trips Starts on {{ trip_schedule.start_date }}</div>
    </div>
    <div class="card-body row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-8">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <el-select class="select-default" v-model="vessel_detail_id" placeholder="Search by Vessel" size="small">
                <el-option :value="vessel.id" :label="vessel.name" v-for="vessel in vessels" :key="vessel.id">{{ vessel.name }}</el-option>
              </el-select>
              <el-select class="select-default" v-model="day" placeholder="Search by Route" size="small">
                    <el-option :value="i" :label="day" v-for="day, i in $store.state.days" :key="day">{{ day }}</el-option>
                  </el-select>
              <el-select class="select-default" v-model="route_id" placeholder="Search by Route" size="small">
                  <el-option :value="route.id" :label="route.code" v-for="route in routes" :key="route.id">{{ route.code }}</el-option>
                </el-select>
                <el-select class="select-default" v-model="day" placeholder="Search by Day" size="small">
                    <el-option :value="i" :label="day" v-for="day,i in $store.state.days" :key="day">{{ day }}</el-option>
                  </el-select>
            <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
              <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped" header-row-class-name="text-primary" :data="trips" style="width: 100%">

              <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Route">
                <template slot-scope="props">
                  {{ props.row.route.code }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Vessel">
                <template slot-scope="props">
                  {{ props.row.vessel.name }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Time">
                  <template slot-scope="props">
                    {{ props.row.time }}
                  </template>
                </el-table-column>

                <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Day">
                  <template slot-scope="props">
                    {{ $store.state.days[props.row.day] }}
                  </template>
                </el-table-column>

                <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Status">
                    <template slot-scope="props">
                      <el-tag :type="props.row.status == 1 ? 'primary' : 'warning'"> {{ props.row.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</el-tag>
                    </template>
                  </el-table-column>

              <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
                <template slot-scope="props">
                  
                  <el-button @click="handleUpdateStatus(props.row)" :type="props.row.status == 1 ? 'warning' : 'primary'" size="mini" plain>{{ props.row.status == 1 ? 'DISABLE' : 'ENABLE' }}</el-button>

                  <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import {
  TripSchedule
} from '@/resources/trip_schedule'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import TripGenerateForm from './TripGenerateForm.vue'
import {
  Route
} from '@/resources/route'
import {
  Vessel
} from '@/resources/vessel'


export default {
  components: {
    ConfirmDelete,
    TripGenerateForm
  },
  props: ['trip_schedule'],
  data() {
    return {
      showDelete: false,
      showForm: false,
      day: 0,
      trips: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      term: null,
      vessels: [],
      routes: [],
      vessel_detail_id: null,
      route_id: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTrips()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTrips()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTrips()
    },
    route_id: function () {
      this.pagination.currentPage = 1
      this.getTrips()
    },
    vessel_detail_id: function () {
      this.pagination.currentPage = 1
      this.getTrips()
    },
    day: function () {
      this.pagination.currentPage = 1
      this.getTrips()
    }
  },
  created: function () {
    this.getRoutes()
    this.getVessels()
    this.getTrips()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleUpdateStatus(model) {
      this.$loading = true

      model.status = model.status == 1 ? 0 : 1

      TripSchedule.updateTrip(this.trip_schedule.id, model)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Trip has been updated',
          });
          this.getTrips()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      TripSchedule.deleteTrip(model.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Trip has been removed',
          });
          this.getTrips()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTrips() {
      this.handleClose()
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        route_id: this.route_id,
        vessel_detail_id: this.vessel_detail_id,
        day: this.day || null
      }
      this.$loading = true
      TripSchedule.getTrips(this.trip_schedule.id, {
          params: params
        })
        .then(result => {
          if (result.data.trips)
            this.trips = result.data.trips
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getRoutes() {
      this.$loading = true
      Route.get({})
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getVessels() {
      this.$loading = true
      Vessel.get({})
        .then(result => {
          if (result.data.vessels)
            this.vessels = result.data.vessels
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
