import api from './api'

const API = '/api/client/trip-schedules'

export const TripSchedule = {
  deleteTrip: function(id) {
    return api.delete(`${API}/delete-trip/${id}`)
  },
  updateTrip: function(id, form) {
    return api.create(`${API}/${id}/update-trip`, form)
  },
  addTrips: function(id, form) {
    return api.create(`${API}/${id}/add-trips`, form)
  },
  getTrips: function(id, params) {
    return api.get(`${API}/${id}/trips`, params)
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
