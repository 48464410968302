<template>
<div class="row">
  <el-drawer title="" :visible.sync="showAssignSeat" direction="rtl" :before-close="handleClose" size="70%" :destroy-on-close="true" :append-to-body="true">
    <div>
      <ticket-assign-seat :ticket="selected" :booking="booking" v-if="selected" @save="getTickets()"></ticket-assign-seat>
    </div>
  </el-drawer>
  <div class="col-md-12 card">
    <div class="card-header" v-if="booking">
      <div style="font-size: 20px;"> {{ booking.route.code }}</div>
      <div class=""> <span style="font-weight: bold;">{{ booking.booking_date }} | {{ booking.formatted_time }}</span></div>

    </div>
    <div class="card-body row">
      <div class="col-sm-2 mb-2">
        <el-select v-model="pagination.perPage" placeholder="Per page" size="small" class="full">
          <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>

      </div>
      <div class="col-sm-2">
        <el-button type="primary" size="small" class="mb-2 full" @click="handleDownload" v-if="isAllowed('bookings', 'print')">Download Manifest</el-button>
        <el-button type="primary" size="small" class="ml-2" @click="handleDownloadGroupTicketBoardingPass" v-if="group_ticket"><i class="fa fa-print"></i> Generate Boarding Passes for the Transaction {{ group_ticket.code }}</el-button>

      </div>
      <div class="col-sm-5">

      </div>
      <div class="col-sm-3">
        <div class="pull-right">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-sm-12 mt-2">
        <div class="table-container">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">
            <el-table-column :min-width="150" fclass-name="td-actions" label="Group Code">
              <template slot-scope="props">
                {{ props.row.group_code }}
              </template>
            </el-table-column>
            <el-table-column :min-width="150" class-name="td-actions" label="Code">
              <template slot-scope="props">
                {{ props.row.ticket_detail.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="150"  class-name="td-actions" label="Passenger Name">
              <template slot-scope="props">
                {{ props.row.ticket_detail.passenger.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80"  class-name="td-actions" label=" Type">
              <template slot-scope="props">
                {{ $store.state.passenger_types[props.row.passenger_type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70"  class-name="td-actions" label="ACMDXN">
              <template slot-scope="props">
                {{ props.row.accommodation.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" class-name="td-actions" label="SEAT NO">
              <template slot-scope="props">
                {{ props.row.ticket_seat ? props.row.ticket_seat.seat_number: ''}}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <div v-if="props.row.status == 2 && booking.status == 1">
                  <!-- <el-tooltip class="item" effect="dark" content="Assign Seat" placement="top">
                    <el-button type="warning" link @click="handleAssignSeat(props.row)" size="mini">
                      <i class="fa fa-sign-in"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Assign and Print Seat" placement="top">
                      <el-button type="success" link @click="handleAssignSeat(props.row, true)" size="mini">
                        <i class="fa fa-sign-in"></i> <i class="fa fa-print"></i>
                      </el-button>
                    </el-tooltip> -->

                  <el-tooltip class="item" effect="dark" content=" Print Seat" placement="top" v-if="isAllowed('checkin', 'print')">
                    <el-button type="primary" link @click="handleDownloadBoardingPass(props.row)" size="mini">
                      <i class="fa fa-print"></i>
                    </el-button>
                  </el-tooltip>

                </div>
                <el-tag v-if="props.row.status == 1">Pending</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  Booking
} from '@/resources/booking'

import {
  Ticket
} from '@/resources/ticket'
import TicketAssignSeat from '../Ticketing/TicketAssignSeat.vue'

export default {
  components: {
    TicketAssignSeat
  },
  props: ['booking'],
  data() {
    return {
      showDelete: false,
      showAssignSeat: false,
      showForm: false,
      showPdf: false,
      tickets: [],
      group_ticket: null,
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      url: null
    }
  },
  watch: {
    booking() {
      this.getTickets()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    this.getTickets()
  },
  methods: {
    handleTicketPrinted() {
      this.showPdf = false
      this.$emit('save', true)
      this.getTickets()
    },
    handleDownload() {
      this.$loading = true
      Booking.downloadManifest(this.booking.id)
        .then(result => {
          this.$loading = false
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
          
        })
        .catch((error) => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadGroupTicketBoardingPass() {
      this.$loading = true
      this.$loadingText = "Preparing to generate boarding pass"

      Ticket.downloadGroupTicketBoardingPass({
          group_ticket_code: this.group_ticket.code
        })
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
    handleDownloadBoardingPass(ticket) {
      this.$loading = true
      this.$loadingText = "Preparing to generate boarding pass"

      Ticket.downloadBoardingPass(ticket.id)
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleAssignSeat(model, print) {
      this.showAssignSeat = true
      this.selected = Object.assign({}, model)
      if (print) this.selected.print = true
    },
    handleView(model) {
      this.showView = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showAssignSeat = false
      this.showView = false
      this.showPdf = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    getTickets() {
      this.handleClose()

      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        booking_id: this.booking.id
      }
      this.$loading = true
      Ticket.fromBooking(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
          this.group_ticket = result.data.group_ticket
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style lang="scss"></style>
