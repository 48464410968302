var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Generate Online Sales Report ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Start Date")]),_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
      { 'has-danger': failed },
      { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Start date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("End Date")]),_c('ValidationProvider',{attrs:{"name":"end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var passed = ref.passed;
      var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
      { 'has-danger': failed },
      { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Start date is required")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }