<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Ticket' : 'Create Ticket' }}
          </h4>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-12">
              <el-checkbox v-model="default_name" @change="setDefaultName">Default Name</el-checkbox>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                <label>Passenger Name</label>
                <ValidationProvider name="full_name" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Passenger name is required' : null" :hasSuccess="passed"
                    name="full_name" v-model="form.full_name">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Age</label>
                <ValidationProvider name="age" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Passenger name is required' : null" :hasSuccess="passed"
                    name="age" v-model="form.age">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Contact Number</label>
                <ValidationProvider name="contact_number" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Passenger name is required' : null" :hasSuccess="passed"
                    name="contact_number" v-model="form.contact_number">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Gender</label>
                <ValidationProvider name="gender" v-slot="{ passed, failed }">
                  <el-select v-model="form.gender" placeholder="Select Gender" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                    <el-option :value="p" :label="p" v-for="p, i in $store.state.gender_list" :key="i">{{ p
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Gender is required</span>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Passenger Type</label>
                <ValidationProvider name="passenger_type" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.passenger_type" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                    <el-option :value="i" :label="p" v-for="p, i in $store.state.passenger_types" :key="i">{{ p
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Passenger type is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Accommodation</label>
                <ValidationProvider name="accommodation" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.accommodation_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                    <el-option :value="accommodation.id" :label="accommodation.name"
                      v-for="accommodation in accommodations" :key="accommodation.id">{{ accommodation.name
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Accommodation is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4" v-if="booking">
              <div class="form-group">
                <label>Type</label>
                <ValidationProvider name="type" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.type" placeholder="Select" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                    <el-option :value="t.id" :label="t.label" v-for="t in $store.state.ticket_types" :key="t.id">{{
                      t.label
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Type is required</span>
                </ValidationProvider>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Booking Date</label>
                <ValidationProvider name="booking_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.booking_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Booking Date is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Route</label>
                <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.route_code" placeholder="Select" filterable class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                    <el-option :value="route.code" :label="route.code" v-for="route in routes" :key="route.code">{{
                      route.code
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Route is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Booking Time</label>
                <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.time" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                    <el-option :value="trip.time" :label="trip.formatted_time" v-for="trip in trips" :key="trip.time">{{
                      trip.formatted_time
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Time is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12"></div>

            <div class="col-md-4" v-if="form.type == 2 && booking">
              <div class="form-group">
                <label>Return Date</label>
                <ValidationProvider name="return_date" :rules="isRequired('type')" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.return_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]" :picker-options="pickerOptions">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Return Date is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4" v-if="form.type == 2 && booking">
              <div class="form-group">
                <label>Return Time</label>
                <ValidationProvider name="return_time" :rules="isRequired('type')" v-slot="{ passed, failed }">
                  <el-select v-model="form.return_time" placeholder="Select" filterable class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                    <el-option :value="trip.time" :label="trip.formatted_time" v-for="trip in return_trips"
                      :key="trip.time">{{ trip.formatted_time
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Return time is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12"></div>
            <div class="col-md-8">
              <div class="form-group">
                <label>Promo</label>
                <el-select @change="overridePrice" clearable v-model="promo_item" placeholder="Select"
                  class="form-group" name="promo_item">
                  <el-option :value="promo_item" v-for="promo_item in promo_items" :key="promo_item.id"
                    :label="promo_item.promo.name + '|' + money(promo_item.amount)"></el-option>
                </el-select><br>
              </div>
            </div>
            <div class="col-md-4">
              <label>Is return open?</label><br>
              <el-switch v-model="form.is_open" active-text="Yes" inactive-text="No">
              </el-switch>
            </div>
            <div class="col-md-12">
              <hr>
              <div>
                <div v-if="price">
                  <el-descriptions class="margin-top" title="Summary" :column="3" size="mini" border>
                    <el-descriptions-item v-if="booking">
                      <template slot="label">
                        Booking Info
                      </template>
                      {{ booking.vessel.code }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="booking">
                      <template slot="label">
                        Capacity
                      </template>
                      {{ capacity }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="booking">
                      <template slot="label">
                        Available
                      </template>
                      {{ capacity - total_passengers }}
                    </el-descriptions-item>

                    <el-descriptions-item v-if="return_booking">
                      <template slot="label">
                        Return Info
                      </template>
                      {{ return_booking.vessel.code }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="return_booking">
                      <template slot="label">
                        Capacity
                      </template>
                      {{ return_capacity }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="return_booking">
                      <template slot="label">
                        Available
                      </template>
                      {{ return_capacity - return_total_passengers }}
                    </el-descriptions-item>

                  </el-descriptions>

                  <el-descriptions class="margin-top" title="" :column="3" size="mini" border>
                    <el-descriptions-item v-if="booking">
                      <template slot="label">
                        FCA
                      </template>
                      {{ money(booking.route.origin.fca) }} <span v-if="return_booking">+{{
                        money(return_booking.route.origin.fca) }}</span>
                    </el-descriptions-item>

                    <el-descriptions-item>
                      <template slot="label">
                        TF
                      </template>
                      {{ money(0) }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-user"></i>
                        Fare
                      </template>
                      {{ money(form.amount) }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="return_booking">
                      <template slot="label">
                        <i class="el-icon-user"></i>
                        Return Fare
                      </template>
                      <span v-if="return_price">{{ money(return_price.amount) }}</span>
                    </el-descriptions-item>
                  </el-descriptions>

                  <el-descriptions class="margin-top" title="" :column="1" size="mini" border>

                    <el-descriptions-item>
                      <template slot="label">
                        Total Fare
                      </template>


                      <span style="font-size: 25px;font-weight: bold;"> {{ money(over_all_fare) }}</span>

                    </el-descriptions-item>

                  </el-descriptions>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer text-right">
          <el-button type="primary" native-type="submit" plain :disabled="!price && !form.booking">Save</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Ticket
} from '@/resources/ticket'
import {
  Accommodation
} from '@/resources/accommodation'
import {
  Route
} from '@/resources/route'

import {
  Booking
} from '@/resources/booking'

import {
  PromoItem
} from '@/resources/promo_item'
import moment from 'moment';

extend("required", required);

export default {
  props: ['ticket'],
  data() {
    return {
      form: {
        booking_date: new Date(),
        full_name: null,
        passenger_type: 0,
        gender: 'MALE',
        accommodation_id: null,
        route_code: null,
        time: null,
        type: 1,
        return_date: null,
        return_time: null,
        return_route_code: null,
        return_booking_id: null
      },
      promo_item: null,
      trips: [],
      return_trips: [],
      accommodations: [],
      routes: [],
      capacity: 0,
      total_passengers: 0,
      price: null,
      booking: null,
      return_booking: null,
      return_capacity: 0,
      return_total_passengers: 0,
      return_price: null,
      promo_items: [],
      over_all_fare: 0,
      default_name: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      }
    };
  },
  watch: {
    ticket: function () {
      if (this.ticket) this.form = Object.assign({}, this.ticket)
    },
    'form.type': function () {
      if (this.form.type == 2) {
        this.form.return_date = this.form.booking_date
      } else {
        this.form.return_date = null
        this.form.return_time = null
        this.form.return_booking_id = null
        this.return_booking = null
        if (this.form.route_code && this.form.time) this.getBooking()
      }
    },
    'form.booking_date': function () {
      // this.form.route_code = null
      if (this.form.type == 2) {
        this.form.return_date = this.form.booking_date
      } else {
        this.form.return_date = null
        this.form.return_time = null
        this.form.return_booking_id = null
        this.return_booking = null
      }
      this.clearData()
      this.getRoutes()
    },
    'form.return_date': function () {
      this.form.return_time = null
      this.form.return_booking_id = null
      this.return_booking = null
      this.getReturnTrips()
    },
    'form.return_time': function () {
      this.getReturnBooking()
    },
    'form.route_code': function () {
      this.form.time = null
      this.booking = null
      this.getTrips()
    },
    'form.time': function () {
      if (this.form.route_code && this.form.time) {
        this.getBooking()

        if (this.form.type == 2) {
          this.form.return_time = null
          this.form.return_booking_id = null
          this.return_booking = null
          this.getReturnTrips()
        }
      }
    },
    'form.accommodation_id': function () {
      if (this.form.route_code && this.form.time) {
        this.getBooking()
      }
    },
    'form.passenger_type': function () {
      if (this.form.route_code && this.form.time) {
        this.getBooking()
      }
    }
  },
  created: function () {
    if (this.ticket) this.form = Object.assign({}, this.ticket)
    this.getAccommodations()
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {
    setDefaultName() {
      if (this.default_name) this.form.full_name = "PASSENGER"
    },
    overridePrice(promo_item) {
      this.form.amount = promo_item ? promo_item.amount : this.price.amount
      this.form.promo_item_id = promo_item ? promo_item.id : null
      this.computeOverAllFare()
    },
    isRequired(field) {
      if (field == 'type') {
        return this.form.type == 2 ? 'required' : ''
      }
      return ''
    },
    checkReturnTrips() {
      this.getReturnTrips()
    },
    clearData() {
      this.booking = null
      this.price = null
      this.capacity = 0
      this.total_passengers = 0
      this.form.booking_id = null
    },
    submit() {
      this.$loading = true

      this.form.promo_item_id = this.promo_item ? this.promo_item.id : null

      let request = this.form.id ? Ticket.update(this.form) : Ticket.create({
        tickets: [this.form]
      })

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket has been saved',
          });
          this.form.full_name = this.default_name ? "PASSENGER":""
          this.form.gender = ""
          this.form.contact_number = ""
          this.capacity = result.data.capacity
          this.total_passengers = result.data.total_passengers
          this.getBooking()
          if (this.form.type == 2) {
            this.getReturnBooking()
          }

          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    computeOverAllFare() {
      this.over_all_fare = this.price.amount
      if (this.form.type == 2 && this.return_price) this.over_all_fare += this.return_price.amount
      if (this.promo_item) {
        this.over_all_fare = this.promo_item.amount
        if (this.promo_item.promo.is_round_trip) this.over_all_fare += this.promo_item.return_trip_amount
      }

      if (this.booking) this.over_all_fare += this.booking.route.origin.fca
      if (this.return_booking) this.over_all_fare += this.return_booking.route.origin.fca
    },
    getBooking() {
      if (!this.form.booking_date) return
      if (!this.form.time) return

      this.$loading = true
      this.promo_items = []
      this.form.amount = 0
      this.form.promo_item = null
      this.form.promo_item_id = null
      this.clearData()
      Booking.getBooking({
          date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
          route_code: this.form.route_code,
          time: this.form.time,
          accommodation_id: this.form.accommodation_id,
          passenger_type: this.form.passenger_type,
          include_price: true,
          is_round_trip: this.form.type == 2
        })
        .then(result => {
          if (result.data.booking)
            this.booking = result.data.booking
          this.form.booking_id = result.data.booking.id
          this.capacity = result.data.capacity
          this.total_passengers = result.data.total_passengers
          this.price = result.data.price

          this.form.return_booking_id = null
          this.return_booking = null
          this.return_capacity = 0
          this.return_total_passengers = 0
          this.return_price = 0
          this.findAvailablePromos()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getReturnBooking() {

      if (!this.form.return_date) return
      this.$loading = true
      this.form.return_booking_id = null
      this.return_booking = null
      this.return_capacity = 0
      this.return_total_passengers = 0
      this.return_price = 0

      Booking.getBooking({
          date: this.$moment(this.form.return_date).format('YYYY-MM-DD'),
          route_code: this.form.return_route_code,
          time: this.form.return_time,
          accommodation_id: this.form.accommodation_id,
          passenger_type: this.form.passenger_type,
          include_price: true
        })
        .then(result => {
          if (result.data.booking)
            this.return_booking = result.data.booking
          this.form.return_booking_id = result.data.booking.id
          this.return_capacity = result.data.capacity
          this.return_total_passengers = result.data.total_passengers
          this.return_price = result.data.price
          this.findAvailablePromos()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTrips() {
      this.trips = []
      if (!this.form.booking_date) return
      if (!this.form.route_code) return
      this.$loading = true
      this.form.time = null
      Booking.getTrips({
          date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
          route_code: this.form.route_code,
          per_page: 999,
          time_only: true
        })
        .then(result => {
          if (result.data.trips)
            this.trips = result.data.trips
          if (this.trips.length) this.form.time = this.trips[0].time
        })
        .catch(() => {
          if (this.form.type == 2) this.form.type = 1

        }).finally(() => {
          this.$loading = false
        })
    },
    getReturnTrips() {
      this.return_trips = []
      if (!this.form.return_date) return
      if (this.form.return_date < this.form.booking_date) {
        this.$notify({
          type: 'error',
          title: "Error",
          text: 'Return date must not be earlier than the booking date',
        });
        this.form.return_date = null
        return
      }
      if (!this.form.route_code) return
      this.$loading = true
      Booking.getTrips({
          date: this.$moment(this.form.return_date).format('YYYY-MM-DD'),
          route_code: this.form.route_code,
          per_page: 999,
          time_only: true,
          origin_date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
          origin_time: this.form.time,
          return_time: true
        })
        .then(result => {
          if (result.data.trips)
            this.return_trips = result.data.trips
          if (this.return_trips.length) this.form.return_time = this.return_trips[0].time
          this.form.return_route_code = result.data.return_route_code
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getRoutes() {
      this.$loading = true
      this.form.route_code = null
      Booking.getRoutes({
          date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
          per_page: 999
        })
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
          if (this.routes.length) this.form.route_code = this.routes[0].code
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getAccommodations() {
      this.$loading = true
      Accommodation.get({})
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
          if (this.accommodations.length) this.form.accommodation_id = this.accommodations[0].id

          this.getRoutes()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    findAvailablePromos() {
      if (this.price) this.form.amount = this.price.amount
      this.promo_items = []
      this.form.promo_item = null
      this.promo_item = null
      this.$loading = true
      PromoItem.findAvailablePromos({
        is_round_trip: this.form.type == 2 ? 1 : 0,
        price_id: this.price.id
      })
        .then(result => {
          this.promo_items = result.data.promo_items
          this.computeOverAllFare()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
