<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showPdf" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <ticket-pdf-viewer :url="url" v-if="url" @print="handleTicketPrinted()"></ticket-pdf-viewer>
    </el-drawer>

    <el-drawer title="" :visible.sync="showAssignSeat" direction="btt" :before-close="getTickets" size="70%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <group-ticket-assign-seat :group_ticket="group_ticket" v-if="group_ticket"></group-ticket-assign-seat>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showReschedulesHistory" direction="btt" :before-close="handleClose" size="50%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <reschedules-history :ticket="selected" v-if="selected"></reschedules-history>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showResched" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <reschedule-ticket-form :ticket="selected" v-if="selected" @save="getTickets()"></reschedule-ticket-form>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showUpgrade" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <upgrade-ticket-form :ticket="selected" v-if="selected" @save="getTickets()"></upgrade-ticket-form>
        </div>
      </div>
    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header" v-if="group_ticket">
        <div class="category">Transaction Code <span style="font-weight: bold;">{{ group_ticket.code }}</span></div>
        <div>Created on: {{ group_ticket.created_at }}</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <confirm-delete v-if="group_ticket && group_ticket.status != 2" klass="ml-2" @onClick="handleDeleteAll()"
            :message=" ' Are you sure you want to remove all tickets?  '" label="Remove all tickets"></confirm-delete>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Passenger Name">
              <template slot-scope="props">
                {{ props.row.ticket_detail.passenger.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="60" fixed="right" class-name="td-actions" label="Ticket">
              <template slot-scope="props">
                {{ props.row.type == 1 ? 'OW' : 'RT' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label=" Type">
              <template slot-scope="props">
                {{ $store.state.passenger_types[props.row.passenger_type] }}
              </template>
            </el-table-column>
            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label="Route">
              <template slot-scope="props">
                {{ props.row.booking.route.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="ACMDXN">
              <template slot-scope="props">
                {{ props.row.accommodation.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label="Date/Time">
              <template slot-scope="props">
                {{ props.row.booking.booking_date }}<br> {{ props.row.booking.formatted_time }}
              </template>
            </el-table-column>
            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label="Total Fare">
              <template slot-scope="props">
                {{ money(props.row.ticket_detail.total_fare) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label="Seat No">
              <template slot-scope="props">
                {{ props.row.ticket_seat ? props.row.ticket_seat.seat_number : 'N/A' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label="STATUS">
              <template slot-scope="props">
                <div v-if="props.row.is_refunded">REFUNDED</div>
                <div v-else>
                  {{ $store.state.tickets_status[props.row.status] }}
                </div>
                <div v-if="props.row.has_been_rescheduled">
                  (RESCHEDULED) <el-link type="warning"
                    @click="handleShowReschedulesHistory(props.row)">(SHOW)</el-link>
                </div>

                <div v-if="props.row.reject_status == 1">
                  (REJECT REQUESTED)
                </div>
                <div v-if="props.row.reject_status == 2">
                  (REJECTED)
                </div>

                <div v-if="props.row.is_expired">EXPIRED</div>


              </template>
            </el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <div v-if="props.row.status == 2">

                  <el-tooltip class="item" effect="dark" content="Reject Ticket" placement="top"
                    v-if="props.row.can_reject && user_id == props.row.created_by_id">
                    <confirm-button title="You're requesting to reject this ticket. Ready to proceed?" label=""
                      :size="'small'" :type="'danger'" :icon="'fa fa-ban'" class="mr-2"
                      @onClick="handleRequestReject(props.row)">
                    </confirm-button>

                  </el-tooltip>



                  <el-tooltip class="item" effect="dark" content="Print Ticket" placement="top"
                    v-if="props.row.can_print">
                    <el-button type="default" link @click="handleDownloadTicket(props.row)" size="mini">
                      <i class="fa fa-download"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark"
                    :content="'Refund ' + $store.state.refund_types[props.row.refund_type_option] + ' ' + props.row.refund_rate +' %'"
                    placement="top" v-if="props.row.can_refund">
                    <el-button type="info" link @click="handleRefund(props.row)" size="mini"
                      v-if="props.row.refund_type_option != null">
                      <i class="fa fa-minus-square"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark"
                    :content="'Rebooking ' + $store.state.resched_types[props.row.resched_type_option] + ' ' + props.row.rebooking_rate+' %'"
                    placement="top" v-if="props.row.can_reschedule">
                    <el-button type="primary" link @click="handleResched(props.row)" size="mini"
                      v-if="props.row.resched_type_option != null">
                      <i class="fa fa-mail-forward"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" :content="'Upgrade'" placement="top"
                    v-if="props.row.is_upgradable">
                    <el-button type="warning" link @click="handleUpgrade(props.row)" size="mini">
                      <i class="fa fa-upload"></i>
                    </el-button>
                  </el-tooltip>
                </div>

                <div v-if="props.row.status == 1 && !props.row.online_booking_id">
                  <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                    <i class="fa fa-edit"></i>
                  </el-button>
                  <confirm-delete @onClick="handleDelete(props.row)"
                    :message="props.row.type == 2 ? 'Deleting this ticket will also delete its corresponding partner ticket. Are you sure you want to continue?  ': null"></confirm-delete>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
        <div class="col-md-12" v-if="group_ticket">
          <hr>
          <div>Total Fare: <span style="font-size: 30px;">{{ money(group_ticket.total_fare) }}</span></div>

          <div style="float: right;">
            <el-button v-if="group_ticket && group_ticket.status != 2" type="primary" size="small" plain
              @click="handleAssignSeat()" style="margin-right: 10px;">Assign Seat</el-button>

            <confirm-button title="You're on the verge of saving pending tickets. Ready to proceed?"
              v-if="group_ticket && group_ticket.status < 2" label="Final Save and Print" :size="'small'"
              :type="'primary'" class="mt-3" @onClick="handleSubmit()">
            </confirm-button>

            <el-button v-if="group_ticket && group_ticket.status == 2" type="primary" size="small"
              @click="handlePrintAll()">Print All</el-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GroupTicket
} from '@/resources/group_ticket'

import {
  Ticket
} from '@/resources/ticket'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import ConfirmButton from '../../Common/ConfirmButton.vue'
import TicketPdfViewer from './TicketPdfViewer.vue'
import Swal from 'sweetalert2'
import RescheduleTicketForm from './RescheduleTicketForm.vue'
import UpgradeTicketForm from './UpgradeTicketForm.vue'
import ReschedulesHistory from './ReschedulesHistory.vue'
import GroupTicketAssignSeat from './GroupTicketAssignSeat.vue'

export default {
  components: {
    ConfirmDelete,
    ConfirmButton,
    TicketPdfViewer,
    RescheduleTicketForm,
    UpgradeTicketForm,
    ReschedulesHistory,
    GroupTicketAssignSeat
  },
  props: ['update', 'g_ticket'],
  data() {
    return {
      showAssignSeat: false,
      showReschedulesHistory: false,
      showUpgrade: false,
      showResched: false,
      showDelete: false,
      showView: false,
      showForm: false,
      showPdf: false,
      tickets: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      group_ticket: null,
      term: null,
      url: null,
      user_id: null
    }
  },
  watch: {
    g_ticket() {
      this.group_ticket = Object.assign({}, this.g_ticket)
      this.getTickets()
    },
    update() {
      this.getTickets()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    if (this.g_ticket) this.group_ticket = Object.assign({}, this.g_ticket)
    this.getTickets()
  },
  methods: {
    handleAssignSeat() {
      this.showAssignSeat = true
    },
    handleShowReschedulesHistory(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showReschedulesHistory = true
    },
    handleDeleteAll() {
      GroupTicket.delete(this.group_ticket)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'All tickets have been removed',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleTicketPrinted() {
      this.showPdf = false
      this.$emit('save', true)
      this.getTickets()
    },
    handleDownload() {

    },
    handleRequestReject(ticket) {
      this.$loading = true
      this.$loadingText = "Requesting to reject"

      Ticket.requestReject(ticket.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Reject request has been submitted',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadTicket(ticket) {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.downloadTicket(ticket.id)
        .then(result => {
          this.url = result.data.url
          this.showPdf = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handlePrintAll() {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.downloadTickets({
          group_ticket_code: this.group_ticket.code
        })
        .then(result => {
          this.url = result.data.url
          this.showPdf = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleSubmit() {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.saveAndPrint(this.group_ticket.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Transaction has been confirmed',
          });

          this.url = result.data.url
          this.showPdf = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleView(model) {
      this.showView = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showView = false
      this.showPdf = false
      this.showResched = false
      this.showUpgrade = false
      this.showReschedulesHistory = false
      this.showAssignSeat = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      Ticket.delete({
          id: model.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket has been removed',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTickets() {
      this.handleClose()

      if (!this.g_ticket) {
        this.getPendingTickets()
        return
      }

      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        group_ticket_code: this.group_ticket?.code
      }
      this.$loading = true
      Ticket.get(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
          this.group_ticket = result.data.group_ticket
          this.user_id = result.data.user_id
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPendingTickets() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      Ticket.pendingTickets(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
          this.group_ticket = result.data.group_ticket
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleUpgrade(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showUpgrade = true
    },
    handleResched(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showResched = true
    },
    async handleRefund(ticket) {
      let message = ticket.refund_type_option != null ? this.$store.state.refund_types[ticket.refund_type_option]+' '+ticket.refund_rate+ ' %' : ''
      if (ticket.refund_rate == 0) message = "(Free)"
      const {
        value: res
      } = await Swal.fire({
        title: message + "  Refund ",
        text: "This will charge the ticket " + this.money(ticket.expected_refund_amount),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-info",
        buttonsStyling: false
      })

      if (!res) return

      this.$loading = true
      Ticket.refund(ticket.id)
        .then(result => {
          this.successRefund(ticket)
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    successRefund(ticket) {
      Swal.fire({
        title: "Success",
        text: "The ticket of passenger " + ticket.ticket_detail.passenger.full_name + ' has been refunded',
        icon: "success",
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })
    }
  }
}
</script>

<style lang="scss"></style>
