<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ form.id ? 'Update Route' : 'Create New Route' }}
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Port of Origin</label>
          <ValidationProvider name="port_origin_id" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.port_origin_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              <el-option :value="p.id" :label="p.name" v-for="p in ports" :key="p.id">{{ p.name }}</el-option>
            </el-select><br>
            <span class="error-text" v-if="failed">Port of origin is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Port of Destination</label>
          <ValidationProvider name="port_dest_id" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.port_dest_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              <el-option :value="p.id" :label="p.name" v-for="p in ports" :key="p.id">{{ p.name }}</el-option>
            </el-select><br>
            <span class="error-text" v-if="failed">Port of destination is required</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="card-footer text-right">
        <p-button type="info" native-type="submit">Save</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Route
} from '@/resources/route'
import {
  Port
} from '@/resources/port'

extend("required", required);

export default {
  props: ['route'],
  data() {
    return {
      form: {
        code: "",
        port_origin_id: null,
        port_dest_id: null
      },
      ports: []
    };
  },
  watch: {
    route: function () {
      if (this.route) this.form = Object.assign({}, this.route)
    }
  },
  created: function () {
    if (this.route) this.form = Object.assign({}, this.route)
    this.getPorts()
  },
  beforeDestroy() {
    this.form = {
      code: "",
    }
  },
  methods: {
    getPorts() {
      let params = {}
      this.$loading = true
      Port.get({
          params: params
        })
        .then(result => {
          if (result.data.ports)
            this.ports = result.data.ports
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch ports',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? Route.update(this.form) : Route.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Route has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
