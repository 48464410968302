<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showAssignSeat" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true" :append-to-body="true">
      <div>
        <ticket-assign-seat :ticket="selected" :booking="booking" v-if="selected"
          @save="updateSeat($event)"></ticket-assign-seat>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showVesselSeats" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="" v-if="booking && booking.vessel">
        <vessel-seats-list :vessel="booking.vessel" :booking="booking"></vessel-seats-list>
      </div>
    </el-drawer>

    <div class="col-md-12">

      <h5 class="title">
        <back></back>
        CHECK-IN PASSENGERS
      </h5>
    </div>

    <div class="col-md-4 mb-2">

      <el-card v-if="booking" style="margin-bottom: 10px;">
        <div>

        </div>
        <div style="text-align: center;">
          <div>STATUS</div>
          <div style="font-weight: bold;font-size: 20px;">{{ $store.state.bookings_status[booking.status] }}</div>
          <div class="bold">{{ booking.route_code }} ({{ booking.vessel.name }})</div>
          <div class="bold">{{ booking.booking_date }} | {{ booking.formatted_time }}</div>
          <div class="text-small">BOARDED: {{booking.boarded_tickets}}</div>
          <div class="text-small">NOT YET BOARDED: {{ booking.pending_boarded_tickets }}</div>
          <div class=" text-success" style="cursor: pointer;"><a @click="handleVesselSeats">SEAT PLAN</a></div>
          <hr>
          <el-button type="warning" @click="getCheckinTickets()" size="mini">UPDATE LIST</el-button>

        </div>
        <div>
        </div>
      </el-card>

      <div>
        <el-button @click="showScanner = true" type="info" size="mini">SCAN QR CODE</el-button>
        <el-button @click="showScanner = false" type="danger" size="mini" style="float: right;" v-if="showScanner"><i
            class="fa fa-close"></i></el-button>
      </div>
      <div style="background-color: #909399;" v-if="showScanner">
        <CameraCodeScanner @scan="onScan" @load="onLoad" v-if="showScanner"></CameraCodeScanner>
      </div>
      <hr>
      <div>
        <form @submit.prevent="handleSubmit">
          <el-input v-model="term" placeholder="Search keyword" class="input-code" size="small">
            <el-button slot="append" class="primary" style="margin-right: 10px;" native-type="submit"
              size="small">SEARCH BY
              CODE OR NAME</el-button>
            <el-button slot="append" type="button" style="background-color: #909399;color: #fff;" size="small"
              @click="clear">CLEAR</el-button>
          </el-input>

          <!-- <el-input v-model="name" placeholder="Search by name" class="input-code" size="small">
            <el-button slot="append" class="primary" style="margin-right: 10px;" native-type="submit"
              size="small">SEARCH BY
              NAME</el-button>
            <el-button slot="append" type="button" style="background-color: #909399;color: #fff;" size="small"
              @click="clear">CLEAR</el-button>
          </el-input> -->
        </form>
      </div>
    </div>
    <div class="col-md-4 mb-2">
      <div v-if="!$loading && !found_tickets.length">NO PASSENGERS FOUND</div>
      <el-button class="full" @click="handleDownloadBoardingPass(code)" type="success" size="large"
        v-if="found_tickets.length && code"><i class="fa fa-print"></i> PRINT GROUP BOARDING PASS</el-button>

      <div v-for="ticket in found_tickets" :key="ticket.id" style="margin-bottom: 10px;">
        <el-card :class="ticket.ticket_seat ? 'bg-light-blue' : ''">
          <div>GROUP CODE: {{ ticket.group_code }}</div>
          <div>CODE: {{ ticket.code }}</div>
          <div>FULL NAME: {{ ticket.full_name }}</div>
          <div>GENDER: {{ $store.state.gender_list[ticket.gender] }}</div>
          <div>ACCO: {{ ticket.accommodation.name }}</div>
          <div>SEAT NO.: <span style="font-weight: bold;">{{ ticket.ticket_seat ? ticket.ticket_seat.seat_number : ''
              }}</span></div>
          <hr>
          <div>
            <el-button @click="handleAssignSeat(ticket)" plain type="primary" size="mini">{{ ticket.ticket_seat ?
              'CHANGE' : 'ASSIGN' }} SEAT</el-button>
            <el-button @click="handleDownloadBoardingPass(ticket.code)" type="success" size="mini"
              v-if="ticket.ticket_seat"><i class="fa fa-print"></i> PRINT</el-button>
          </div>
        </el-card>
      </div>
    </div>

    <div class="col-md-12"></div>
    <div class="col-md-3 mb-2">
      <br>

    </div>
    <div class="col-md-12">
      <hr>
    </div>



  </div>
</template>

<script>
import {
  Booking
} from '@/resources/booking'
import {
  Ticket
} from '@/resources/ticket'
import {
  LocalStorageService
} from '@/services/LocalStorageService'

import {
  CameraCodeScanner
} from "vue-barcode-qrcode-scanner";
import Back from '@/components/Common/Back.vue';
import TicketAssignSeat from '../../Ticketing/TicketAssignSeat.vue';
import VesselSeatsList from '../../Vessels/VesselSeatsList.vue';

export default {
  components: {
    CameraCodeScanner,
    Back,
    TicketAssignSeat,
    VesselSeatsList
  },
  data() {
    return {
      showAssignSeat: false,
      showScanner: false,
      code: null,
      term: null,
      tickets: [],
      found_tickets: [],
      booking: null,
      selected: null,
      showVesselSeats: false
    }
  },
  created: function () {
    this.getCheckinTickets()
  },
  methods: {
    handleVesselSeats() {
      this.showVesselSeats = true
    },
    handleAssignSeat(model) {
      this.showAssignSeat = true
      this.selected = Object.assign({}, model)
      // if (print) this.selected.print = true
    },
    handleClose() {
      this.showAssignSeat = false
      this.showVesselSeats = false
    },
    updateSeat(val) {
      this.selected.ticket_seat = val
      let self = this
      this.tickets.forEach(function (ticket) {
        if (self.selected.id == ticket.id) {
          ticket.ticket_seat = val
        }
      })

      this.handleClose()
    },
    clear() {
      this.code = null
      this.term = null
      this.found_tickets = []
    },
    onLoad({
      controls,
      scannerElement,
      browserMultiFormatReader
    }) {

    },
    onScan({
      result,
      raw
    }) {
      this.term = result
      this.showScanner = false
      this.searchFromTickets()
    },
    handleSubmit() {
      this.searchFromTickets()
    },
    searchFromTickets() {
      // if (!this.code && !this.name) return false
      this.found_tickets = []
      let foundTickets = []
      let self = this
      let group_codes = []

      if (!this.term) return

      this.$loading = true

      this.tickets.forEach(function (ticket) {

        if (ticket.code == self.term || ticket.group_code == self.term || ticket.online_booking_code == self.term) {
          group_codes.push(ticket.group_code)
          foundTickets.push(ticket)
        } else if (ticket.full_name.indexOf(self.term) !== -1) {
          foundTickets.push(ticket)
        }
      })

      group_codes = [...new Set(group_codes)]
      if (group_codes.length == 1) this.code = group_codes[0]
      this.found_tickets = foundTickets
      this.$loading = false
    },
    getCheckinTickets() {
      this.$loading = true
      LocalStorageService.setCheckinTickets(this.tickets)
      Ticket.checkinTickets({
          booking_id: this.$route.params.id
        })
        .then(result => {
          this.booking = result.data.booking
          this.tickets = result.data.tickets
          LocalStorageService.setCheckinTickets(this.tickets)
          if (this.code) this.searchFromTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadBoardingPass(code) {
      this.$loading = true
      this.$loadingText = "Preparing to generate boarding pass"

      Ticket.downloadGroupTicketBoardingPass({
          code: code
        })
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
  }
}
</script>

<style lang="scss">
.input-code .el-input-group__append .primary {
  background-color: #409EFF;
  color: #ffffff;
}
</style>
