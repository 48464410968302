<template>
  <div>
    <h4>ASSIGN SEAT</h4>
    <div class="row">
      <div class="col-md-3">
        <el-button type="success" @click="handleAssignSeatNumberToAll()">AUTO-ASSIGN SEAT NUMBER TO ALL TICKETS</el-button>

        <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">
          <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Passenger">
            <template slot-scope="props">
              {{ props.row.ticket_detail.passenger.full_name }}
              <div>{{ props.row.accommodation.name }} | {{ props.row.booking.route.code }}</div>
            </template>
          </el-table-column>
          <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Seat No">
            <template slot-scope="props">
              <div v-if="props.row.ticket_seat">{{ props.row.ticket_seat.seat_number }}</div>
              <div>
                <el-button size="mini" @click="handleAssignSeat(props.row)">{{ props.row.ticket_seat ? 'CHANGE':'ASSIGN'
                  }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-md-9">
        <div>
          <ticket-assign-seat :ticket="selected" :booking="selected.booking" v-if="selected"
            @save="updateSeat($event)"></ticket-assign-seat>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  Ticket
} from '@/resources/ticket'
import {
  GroupTicket
} from '@/resources/group_ticket'
import TicketAssignSeat from './TicketAssignSeat.vue';

export default {
  components: { TicketAssignSeat },
  props: ['group_ticket'],
  data() {
    return {
      tickets: [],
      selected: null
    };
  },
  watch: {
    group_ticket: function () {
      this.getTickets()
    }
  },
  created: function () {
    if (this.group_ticket) this.getTickets()
  },
  methods: {
    handleAssignSeatNumberToAll() {
      this.$loading = true
      GroupTicket.assignSeatNumberToAll(this.group_ticket.code)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Seat number has been assigned',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleAssignSeat(ticket) {
      this.selected = null
      this.selected = Object.assign({}, ticket)
    },
    updateSeat(result) {
      if (this.selected && result && result.ticket_id == this.selected.id) {
        let self = this
        this.tickets.forEach(function (ticket) {
          if (ticket.id == result.ticket_id) {
            ticket.ticket_seat = result
            self.selected = Object.assign({}, ticket)
          }
        })
      }

    },
    getTickets() {
      this.$loading = true

      let params = {
        page: 1,
        per_page: 9999,
        group_ticket_code: this.group_ticket?.code
      }

      this.selected = null

      Ticket.get(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
