<template>
  <div class="row">
    <div class="col-md-4">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                Generate Online Sales Report
              </h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Start Date</label>
                <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Start date is required</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>End Date</label>
                <ValidationProvider name="end_date" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Start date is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="card-footer text-right">
              <p-button type="info" native-type="submit">Save</p-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Report
} from '@/resources/report'

extend("required", required);

export default {
  data() {
    return {
      form: {
        start_date: new Date(),
        end_date: new Date()
      }
    };
  },
  created: function () {
    // if (this.trip_schedule) this.form = Object.assign({}, this.trip_schedule)
  },
  methods: {
    submit() {
      this.$loading = true
      this.form.start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      this.form.end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')
      Report.getOnlineSalesReport(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Report has been generated',
          });
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
